import { Box, Divider, IconButton, Stack } from '@mui/material';
import { BaseTimezoneSelect } from 'components';
import { BaseDataAndTimePicker } from 'features/BaseDataAndTimePicker';
import { useTimeZone } from 'features/TimeZoneManager/hooks';
import { AddTimeZone, TimeZone } from 'features/TimeZoneManager/timeZoneModels';
import { t } from 'i18next';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { close_popup } from 'shared/assets';
import { useAnchorEl, useAppSelector } from 'shared/hooks';
import { BaseBtn, BaseCheckbox, BaseInput, BigTitle, Confirm, Modal, Row } from 'shared/ui';

interface Props {
	openModal: boolean;
	onCloseModal: () => void;
	timeZone: TimeZone;
	updateTimeZoneData: () => void;
}

export const AddTimeZoneModal: FC<Props> = ({
	openModal,
	onCloseModal,
	timeZone,
	updateTimeZoneData
}) => {
	const { addTimeZone } = useTimeZone()
	const { timeZones } = useAppSelector((st) => st.timeZone);
	const [newZoneStart, setNewZoneStart] = useState(null);
	const [newZoneEnd, setNewZoneEnd] = useState(null);
	const [utcOffset, setUtcOffset] = useState(0);
	const [isTimeZoneChanged, setIsTimeZoneChanged] = useState(false);
	const [isValidZoneStartEnd, setIsValidZoneStartEnd] = useState(true);

	const [isSync, setIsSync] = useState<boolean>(false);
	const [isActive, setIsActive] = useState<boolean>(false);
	const [isDST, setIsDST] = useState<boolean>(false);
	const [hasDST, setHasDST] = useState<boolean>(false);
	const [displayName, setDisplayName] = useState("");
	const [zoneName, setZoneName] = useState("");
	const { isOpen, anchorEl, onOpenMenu, onCloseMenu } = useAnchorEl();
	const [selectTimezone, setSelectTimezone] = useState<string>(null);
	const changeNewZoneStart = (value) => {
		setNewZoneStart(value)
		setIsTimeZoneChanged(true);
		setIsValidZoneStartEnd(true)
	}
	const changeNewZoneEnd = (value) => {
		setNewZoneEnd(value)
		setIsTimeZoneChanged(true)
		setIsValidZoneStartEnd(true)
	}
	const onChangeUtcOffset = (value) => {

		const val = value.target.value
		if (val < -11 || val > 11) return
		setUtcOffset(val)
		setIsTimeZoneChanged(true)
	}

	const changeCheckboxIsActive = (e: ChangeEvent<HTMLInputElement>) => {
		setIsTimeZoneChanged(true)
		setIsActive(e.target.checked)
	}

	const changeCheckboxIsDST = (e: ChangeEvent<HTMLInputElement>) => {
		if (isSync) return
		setIsTimeZoneChanged(true)
		setIsDST(e.target.checked)
	}
	const changeHasDst = (e: ChangeEvent<HTMLInputElement>) => {
		setIsTimeZoneChanged(true)
		setHasDST(e.target.checked)
	}
	const checkIsZonePeriodIsValid = (newZoneStart: Date, newZoneEnd: Date) => {
		const start = new Date(newZoneStart);
		const end = new Date(newZoneEnd);
		if (start > end) {
			setIsValidZoneStartEnd(false)
			return false
		} else {
			return true
		}
	}

	const onAddTimeZone = async () => {
		const data: AddTimeZone = {
			uTCOffset: utcOffset * 3600,
			dst: isDST,
			isActive: isActive,
			hasDST: hasDST,
			zoneName: zoneName,
			displayName: displayName
		}
		let isValid = true
		if (newZoneStart) {

			const localDate = new Date(newZoneStart);
			const utcTimestamp = Math.floor(localDate.getTime() / 1000);

			data.zoneStart = utcTimestamp
		}
		if (newZoneEnd) {

			const localDate = new Date(newZoneEnd);
			const utcTimestamp = Math.floor(localDate.getTime() / 1000);

			data.zoneEnd = utcTimestamp
			if (newZoneStart) {
				isValid = checkIsZonePeriodIsValid(newZoneStart, newZoneEnd)
			}
		}
		if (!isValid) return
		const result = await addTimeZone(data);
		if (result) {
			updateTimeZoneData()
			onCloseModal()

		}
	}

	useEffect(() => {
		if (!openModal) {
			setNewZoneStart('')
			setIsTimeZoneChanged(false)
			setHasDST(false)
			setIsSync(false);
			setIsActive(true);
			setIsDST(false);
			setNewZoneStart(null)
			setNewZoneEnd(null)
			setUtcOffset(0)
			setDisplayName("");
			setZoneName("");
			setSelectTimezone(null)
		}
	}, [timeZone, openModal])

	const handleCloseEditModal = (e: React.MouseEvent<HTMLElement>) => {
		// if (isLoading) return;
		// setCreatedDateError(null);
		// setStartDateError(null);

		isTimeZoneChanged
			? onOpenMenu(e)
			: onCloseModal();
	};

	const changeDisplayName = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setDisplayName(e.target.value)
	}
	const changeZoneName = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setZoneName(e.target.value)
	}

	const onChangeSelectTimezone = (timezoneId: string) => {
		setSelectTimezone(timezoneId)
		const timeZoneItem = timeZones.find(item => item.timeZoneID === parseInt(timezoneId));
		setNewZoneStart(timeZoneItem.zoneStart * 1000);
		setHasDST(!!timeZoneItem.hasDST);
		setIsActive(!!timeZoneItem.isActive);
		setIsDST(!!timeZoneItem.dst);
		setNewZoneEnd(timeZoneItem.zoneEnd * 1000)
		setUtcOffset(timeZoneItem.utcOffset / 3600)
		setDisplayName(timeZoneItem.displayName);
		setZoneName(timeZoneItem.zoneName);
	}
	return (
		<Modal open={openModal} sx={{ margin: 'auto', width: '680px', padding: "10px" }} onClose={handleCloseEditModal}
		>
			<Box>
				<Stack direction="row" justifyContent="space-between" alignItems="center">
					<BigTitle>{t('time_zone_add_title')} </BigTitle>
					<IconButton onClick={handleCloseEditModal}>
						<img src={close_popup} />
					</IconButton>
				</Stack>
				<Box>
					<Row spacing="20px" sx={{ justifyContent: "space-between", alignItems: "flex-start" }}>
						<Box width="50%" >
							<BigTitle sx={{ textAlign: 'right', fontSize: '20px', mt: '10px' }}>{t('copy_from_time_zone_edit')} </BigTitle></Box>
						<Box width="50%">
							<BaseTimezoneSelect title={`${t('select_all_time_zone')}`} onChange={onChangeSelectTimezone} value={selectTimezone} /></Box>
					</Row>
					<Box sx={{ height: "1px", bgcolor: 'primary.main', m: '10px 0', }}></Box>
					<Row spacing="15px" sx={{ justifyContent: "space-between", alignItems: "flex-start" }}>
						<Box width="50%">
							<BaseInput
								label={`${t('display_name_time_zone_edit')}`}
								name="display_name"
								value={displayName}
								onChange={changeDisplayName}
								error={false}
								helperText={''}
								small
								compact
								smallLabel

							/>
							<BaseCheckbox
								label={t(`is_dst_time_zone_edit`)}
								value={isDST}
								onChange={(e) => changeCheckboxIsDST(e)}
								disabled={isSync}
							/>
							<Box width="255px">
								<BaseDataAndTimePicker
									label={`${t('dst_start_time_zone_edit')}`}
									name="dstStart"
									value={newZoneStart}
									onChange={(name, value) => {
										changeNewZoneStart(value);
									}}
									changedText={(value) => {
										changeNewZoneStart(value);
									}}
									showFuture
									showError={!isValidZoneStartEnd}
									errorMessage={t('dst_period_is_not_valid')}
									disabled={isSync}
								/>
							</Box>
							<BaseInput
								label={`${t('utc_offset_time_zone_edit')}`}
								name="utc_offset"
								value={utcOffset}
								onChange={onChangeUtcOffset}
								error={false}
								helperText={''}
								type="number"
								small
								compact
								smallLabel

								valueForNumberChanging={1}
								disabled={isSync}
							/>
						</Box>
						<Box width="50%">
							<BaseInput
								label={`${t('zone_name_time_zone_edit')}`}
								name="zone_name"
								value={zoneName}
								onChange={changeZoneName}
								error={false}
								helperText={''}
								small
								compact
								smallLabel

							/>
							<Box sx={{ width: '255px', height: '44px' }}></Box>
							<Box width="255px">
								<BaseDataAndTimePicker
									label={`${t('dst_end_time_zone_edit')}`}
									name="dstEnd"
									value={newZoneEnd}
									onChange={(name, value) => {
										changeNewZoneEnd(value);
									}}
									changedText={(value) => {
										changeNewZoneEnd(value);
									}}
									showFuture
									showError={!isValidZoneStartEnd}
									errorMessage={t('dst_period_is_not_valid')}
									disabled={isSync}
								/>
							</Box>

							<BaseCheckbox
								label={t(`is_active_in_application_time_zone_edit`)}
								value={isActive}
								onChange={(e) => changeCheckboxIsActive(e)}
							/>

							<BaseCheckbox
								label={t(`has_dst_time_zone_edit`)}
								value={hasDST}
								onChange={(e) => changeHasDst(e)}
							/>


						</Box>
					</Row>
				</Box>
				<Row justifyContent="flex-end" sx={{ marginTop: "16px" }}>
					<Row width={300} gap="10px" >

						<BaseBtn onClick={handleCloseEditModal} btnColor="info.main" fullWidth>
							{t('cancel_time_zone_edit_modal')}
						</BaseBtn>

						<BaseBtn disabled={!displayName || !zoneName} onClick={onAddTimeZone} fullWidth>
							{t('save_time_zone_edit_modal')}
						</BaseBtn>
					</Row>
				</Row>
			</Box>
			<Confirm
				open={isOpen}
				anchorEl={anchorEl}
				onClose={onCloseMenu}
				confirm={onCloseModal}
				buttonTexts={[t('cancel_edit_edit_modal'), t('back_to_edit_edit_modal')]}
			/>
		</Modal >
	);
};
