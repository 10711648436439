import { Box } from '@mui/material';
import { BaseTable } from 'components/BaseTable';
import { t } from 'i18next';
import { BaseBtn, Icon, Loader, Row, Text } from 'shared/ui';
import { TABLE_TITLES, TRANSLATE_COLUMNS } from './constants';
import { useAppSelector, useToast } from 'shared/hooks';
import { FC, useEffect, useState } from 'react';
import { useWeatherStationsManager } from 'features/WeatherStationsManager/hooks/api';
import { refresh } from 'shared/assets';

interface Props {
  objectID: number;
  customerID: number;
  setIsChangedPlantType: (status: boolean) => void;
}

export const PlantTypeControlled: FC<Props> = ({ objectID, customerID, setIsChangedPlantType }) => {
  const [selectedTypes, setSelectedTypes] = useState([]);
  const { plantType } = useAppSelector((st) => st.weatherStation);
  const { showSuccess } = useToast();
  const { getPlantTypeData, updatePlantType, loading } = useWeatherStationsManager();
  const updatePlantTypeControlled = async () => {
    const data = plantType.map((item) => ({
      id: item.id,
      status: selectedTypes.includes(item.id) ? 1 : 0,
    }));
    const success = await updatePlantType(data, objectID, customerID);
    if (success) {
      showSuccess(t('update_successful_toast_text'));
      await getPlantTypeData(objectID, customerID);
    }
  };
  useEffect(() => {
    //getPlantTypeData(18453, 143)
    if (objectID && customerID) {
      getPlantTypeData(objectID, customerID);
    }
  }, [objectID, customerID]);

  useEffect(() => {
    const selectedTypes = [];
    plantType.forEach((item) => {
      if (item.status) {
        selectedTypes.push(item.id);
      }
    });
    setSelectedTypes(selectedTypes);
  }, [plantType]);

  const rowSelectionModelChange = (selectedTypes: number[]) => {
    setSelectedTypes(selectedTypes);
  };
  const isDisabledUpdate = (): boolean => {
    const originalSelectedIds = plantType.filter((pt) => pt.status === 1).map((pt) => pt.id);

    const sortedOriginalIds = originalSelectedIds.sort((a, b) => a - b);
    const sortedSelectedTypes = [...selectedTypes].sort((a, b) => a - b);

    if (sortedOriginalIds.length !== sortedSelectedTypes.length) {
      setIsChangedPlantType(false);
      return true;
    }
    for (let i = 0; i < sortedOriginalIds.length; i++) {
      if (sortedOriginalIds[i] !== sortedSelectedTypes[i]) {
        setIsChangedPlantType(false);
        return true;
      }
    }
    setIsChangedPlantType(true);
    return false;
  };
  return (
    <>
      <Row spacing="20px" alignItems="start">
        <Box>
          <Text sx={{ fontSize: '20px', m: 0, fontWeight: 900, color: 'primary.dark' }}>
            {' '}
            {t('plant_type_controlled_meteorological_station_modal')}
          </Text>
        </Box>

        <BaseBtn
          onClick={updatePlantTypeControlled}
          btnColor="primary.dark"
          fullWidth
          sx={{ height: '29px', width: 'auto' }}
          disabled={!isDisabledUpdate()}
        >
          {t('update_meteorological_station_modal')}
        </BaseBtn>
      </Row>
      <Row alignItems="start" position={'relative'}>
        {loading && (
          <Box
            sx={{
              background: '#ffffffcc',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '300px',
              position: 'absolute',
              zIndex: 50,
            }}
          >
            <Loader />
          </Box>
        )}

        <BaseTable
          tableTitles={TABLE_TITLES}
          translateColumns={TRANSLATE_COLUMNS}
          tableItems={plantType}
          loadedItems={true}
          idField={'id'}
          noItemsText={t('no_plant_type_controlled')}
          headerItemsPrefix={'_plant_type_controlled_grid_table'}
          onRowSelectionModelChange={rowSelectionModelChange}
          selectedIDs={selectedTypes}
          height={'300px'}
          checkboxSelection
        />
      </Row>
    </>
  );
};
