import { Box, IconButton, Stack } from '@mui/material';
import { BaseDataAndTimePicker } from 'features/BaseDataAndTimePicker';
import { useTimeZone } from 'features/TimeZoneManager/hooks';
import { TimeZone, UpdateTimeZone } from 'features/TimeZoneManager/timeZoneModels';
import { AssignedCustomer } from 'features/WeatherStationsManager/WeatherStationModels';
import { t } from 'i18next';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { close_popup } from 'shared/assets';
import { BaseBtn, BaseCheckbox, BaseInput, BigTitle, Confirm, Modal, Row } from 'shared/ui';
import { useAnchorEl, useAppSelector, useToast } from 'shared/hooks';
import { IsSyncConfirmModal } from '../IsSyncConfirmModal';
import { IsSingleSyncDataModal } from '..';

interface Props {
	openModal: boolean;
	onCloseModal: () => void;
	timeZone: TimeZone;
	updateTimeZoneData: () => void;
}

export const TimeZoneEditModal: FC<Props> = ({
	openModal,
	onCloseModal,
	timeZone,
	updateTimeZoneData
}) => {
	const { syncTimeZone, updateTimeZone, getTimeZone, isLoading } = useTimeZone()
	const [newZoneStart, setNewZoneStart] = useState(null);
	const [newZoneEnd, setNewZoneEnd] = useState(null);
	const [utcOffset, setUtcOffset] = useState(0);
	const [isTimeZoneChanged, setIsTimeZoneChanged] = useState(false);
	const [isValidZoneStartEnd, setIsValidZoneStartEnd] = useState(true);

	const [isSync, setIsSync] = useState<boolean>(false);
	const [isActive, setIsActive] = useState<boolean>(false);
	const [isDST, setIsDST] = useState<boolean>(false);
	const [hasDST, setHasDST] = useState<boolean>(false);

	const [showIsSyncConfirm, setShowIsSyncConfirm] = useState<boolean>(false);

	const [showIsSingleSyncDataModal, setShowIsSingleSyncDataModal] = useState<boolean>(false);

	const { isOpen, anchorEl, onOpenMenu, onCloseMenu } = useAnchorEl();
	const onSyncData = async () => {
		const { data } = await syncTimeZone(timeZone.zoneName)
		if (data) {

			const { dst, zoneStart, zoneEnd, gmtOffset } = data

			const newZoneStar = zoneStart ? new Date((zoneStart * 1000)) : null
			const newZoneEnd = zoneEnd ? new Date((zoneEnd * 1000)) : null
			const newUtcOffset = gmtOffset ? gmtOffset / 3600 : 0


			setIsDST(!!dst);
			setNewZoneStart(newZoneStar);
			setNewZoneEnd(newZoneEnd);
			setUtcOffset(newUtcOffset)
			setIsTimeZoneChanged(true);
			setIsValidZoneStartEnd(true)
		}
	}
	const changeNewZoneStart = (value) => {
		setNewZoneStart(value)
		setIsTimeZoneChanged(true);
		setIsValidZoneStartEnd(true)
	}
	const changeNewZoneEnd = (value) => {
		setNewZoneEnd(value)
		setIsTimeZoneChanged(true)
		setIsValidZoneStartEnd(true)
	}
	const onChangeUtcOffset = (value) => {

		const val = value.target.value
		if (val < -11 || val > 11) return
		setUtcOffset(val)
		setIsTimeZoneChanged(true)
	}

	const changeCheckboxIsActive = (e: ChangeEvent<HTMLInputElement>) => {
		setIsTimeZoneChanged(true)
		setIsActive(e.target.checked)
	}
	const changeCheckboxIsSync = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.checked) setShowIsSyncConfirm(true)
		//setIsTimeZoneChanged(true)
		setIsSync(e.target.checked)
	}

	const changeCheckboxIsDST = (e: ChangeEvent<HTMLInputElement>) => {
		if (isSync) return
		setIsTimeZoneChanged(true)
		setIsDST(e.target.checked)
	}
	const changeHasDst = (e: ChangeEvent<HTMLInputElement>) => {
		setIsTimeZoneChanged(true)
		setHasDST(e.target.checked)
	}
	const checkIsZonePeriodIsValid = (newZoneStart: Date, newZoneEnd: Date) => {
		const start = new Date(newZoneStart);
		const end = new Date(newZoneEnd);
		if (start > end) {
			setIsValidZoneStartEnd(false)
			return false
		} else {
			return true
		}
	}
	const initialData = () => {
		if (timeZone) {
			const zoneStar = timeZone.zoneStart ? new Date((timeZone.zoneStart * 1000)) : null
			const zoneEnd = timeZone.zoneEnd ? new Date((timeZone.zoneEnd * 1000)) : null
			const utcOffset = timeZone.utcOffset ? timeZone.utcOffset / 3600 : 0
			setHasDST(!!timeZone.hasDST)
			setIsSync(!!timeZone.isSync);
			setIsActive(!!timeZone.isActive);
			setIsDST(!!timeZone.dst);
			setNewZoneStart(zoneStar)
			setNewZoneEnd(zoneEnd)
			setUtcOffset(utcOffset)
		}
	}
	const onUpdateTimeZone = async () => {
		const data: UpdateTimeZone = {
			timeZoneID: timeZone.timeZoneID,
			uTCOffset: utcOffset * 3600,
			dst: isDST,
			isActive: isActive,
			isSync: isSync,
			hasDST: hasDST
		}
		let isValid = true
		if (newZoneStart) {

			const localDate = new Date(newZoneStart);
			const utcTimestamp = Math.floor(localDate.getTime() / 1000);

			data.zoneStart = utcTimestamp
		}
		if (newZoneEnd) {

			const localDate = new Date(newZoneEnd);
			const utcTimestamp = Math.floor(localDate.getTime() / 1000);

			data.zoneEnd = utcTimestamp
			if (newZoneStart) {
				isValid = checkIsZonePeriodIsValid(newZoneStart, newZoneEnd)
			}
		}
		if (!isValid) return
		const result = await updateTimeZone(data);
		if (result) {
			updateTimeZoneData()
			onCloseModal()

		}
	}

	useEffect(() => {
		if (openModal) {
			initialData()
		} else {
			setNewZoneStart('')
			setIsTimeZoneChanged(false)
			setHasDST(false)
			setIsSync(false);
			setIsActive(true);
			setIsDST(false);
			setNewZoneStart(null)
			setNewZoneEnd(null)
			setUtcOffset(0)
		}
	}, [timeZone, openModal])

	const handleCloseEditModal = (e: React.MouseEvent<HTMLElement>) => {
		// if (isLoading) return;
		// setCreatedDateError(null);
		// setStartDateError(null);

		isTimeZoneChanged
			? onOpenMenu(e)
			: onCloseModal();
	};

	const closeIsSyncConfirmModal = () => {
		setShowIsSyncConfirm(false)
		setIsSync(false)
	}
	const confirmIsSync = () => {

		setShowIsSyncConfirm(false)
		setIsTimeZoneChanged(true)
	}

	const closeIsSingleSyncDataModal = () => {
		setShowIsSingleSyncDataModal(false)
	}
	const confirmIsSingleSyncData = () => {
		setShowIsSingleSyncDataModal(false)
		setIsTimeZoneChanged(true)
		onSyncData()
	}
	const openIsSingleSyncDataModal = () => {
		setShowIsSingleSyncDataModal(true)
	}

	return (
		<Modal open={openModal} sx={{ margin: 'auto', width: '680px' }} onClose={handleCloseEditModal}
			padding={'8px 16px'}>
			<Box>
				<Stack direction="row" justifyContent="space-between" alignItems="center">
					<BigTitle>{t('time_zone_edit_title')} </BigTitle>
					<IconButton onClick={handleCloseEditModal}>
						<img src={close_popup} />
					</IconButton>
				</Stack>
				{timeZone && <Box>
					{/* <Box>{timeZone.isSync}</Box>
					<Box>{timeZone.isActive}</Box> */}
					<Row spacing="15px" sx={{ justifyContent: "space-between", alignItems: "flex-start" }}>
						<Box width="50%">
							<BaseInput
								label={`${t('display_name_time_zone_edit')}`}
								name="display_name"
								value={timeZone.displayName}
								onChange={() => { }}
								error={false}
								helperText={''}
								disabled
								small
								compact
								smallLabel
								marginRight
							/>
							<BaseCheckbox
								label={t(`is_dst_time_zone_edit`)}
								value={isDST}
								onChange={(e) => changeCheckboxIsDST(e)}
								disabled={isSync}
							/>
							<Box width="255px">
								<BaseDataAndTimePicker
									label={`${t('dst_start_time_zone_edit')}`}
									name="dstStart"
									value={newZoneStart}
									onChange={(name, value) => {
										changeNewZoneStart(value);
									}}
									changedText={(value) => {
										changeNewZoneStart(value);
									}}
									showFuture
									showError={!isValidZoneStartEnd}
									errorMessage={t('dst_period_is_not_valid')}
									disabled={isSync}
								/>
							</Box>
							<BaseInput
								label={`${t('utc_offset_time_zone_edit')}`}
								name="utc_offset"
								value={utcOffset}
								onChange={onChangeUtcOffset}
								error={false}
								helperText={''}
								type="number"
								small
								compact
								smallLabel
								marginRight
								valueForNumberChanging={1}
								disabled={isSync}
							/>
							{!timeZone.isManual && <BaseBtn loading={isLoading} sx={{ mt: '16px', height: '29px', width: "255px" }} onClick={openIsSingleSyncDataModal} fullWidth>
								{t('sync_data_zone_edit_modal')}
							</BaseBtn>}
						</Box>
						<Box width="50%">
							<BaseInput
								label={`${t('zone_name_time_zone_edit')}`}
								name="zone_name"
								value={timeZone.zoneName}
								onChange={() => { }}
								error={false}
								helperText={''}
								disabled
								small
								compact
								smallLabel
								marginRight
							/>
							<Box sx={{ width: '255px', height: '44px' }}></Box>
							<Box width="255px">
								<BaseDataAndTimePicker
									label={`${t('dst_end_time_zone_edit')}`}
									name="dstEnd"
									value={newZoneEnd}
									onChange={(name, value) => {
										changeNewZoneEnd(value);
									}}
									changedText={(value) => {
										changeNewZoneEnd(value);
									}}
									showFuture
									showError={!isValidZoneStartEnd}
									errorMessage={t('dst_period_is_not_valid')}
									disabled={isSync}
								/>
							</Box>

							<BaseCheckbox
								label={t(`is_active_in_application_time_zone_edit`)}
								value={isActive}
								onChange={(e) => changeCheckboxIsActive(e)}
							/>

							{!timeZone.isManual && <BaseCheckbox
								label={t(`is_auto_sync_time_zone_edit`)}
								value={isSync}
								onChange={(e) => changeCheckboxIsSync(e)}
							/>}

							<BaseCheckbox
								label={t(`has_dst_time_zone_edit`)}
								value={hasDST}
								onChange={(e) => changeHasDst(e)}
							/>


						</Box>
					</Row>
				</Box>}
				<Row justifyContent="flex-end" sx={{ marginTop: "16px" }}>
					<Row width={300} gap="10px" >

						<BaseBtn onClick={handleCloseEditModal} btnColor="info.main" fullWidth>
							{t('cancel_time_zone_edit_modal')}
						</BaseBtn>
						<BaseBtn onClick={onUpdateTimeZone} fullWidth>
							{t('save_time_zone_edit_modal')}
						</BaseBtn>
					</Row>
				</Row>
			</Box>
			<Confirm
				open={isOpen}
				anchorEl={anchorEl}
				onClose={onCloseMenu}
				confirm={onCloseModal}
				buttonTexts={[t('cancel_edit_edit_modal'), t('back_to_edit_edit_modal')]}
			/>
			<IsSyncConfirmModal openModal={showIsSyncConfirm} onCloseModal={closeIsSyncConfirmModal} onConfirm={confirmIsSync} />
			<IsSingleSyncDataModal openModal={showIsSingleSyncDataModal} onCloseModal={closeIsSingleSyncDataModal} onConfirm={confirmIsSingleSyncData} />
		</Modal >
	);
};
