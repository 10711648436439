import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface ISimCheckOperator {
  searchString: string;
  id: string;
}

interface globalState {
  GOOGLE_MAP_KEY: string;
  BATT_REP: {
    REPORT_START_DAYS_AGO: number;
    REPORT_INTERVAL_DAYS: number;
    GRAPH_START_DAYS_AGO: number;
    GRAPH_INTERVAL_DAYS: number;
    DAY_TIME_START: string;
    DAY_TIME_END: string;
    NIGHT_TIME_START: string;
    NIGHT_TIME_END: string;
    MIN_CONNECTION_TIME: number;
    DISCONNECTED_DAYS_IN_THE_PERIOD: number;
    GRAPH_VBAT_MAX_SCALE: number;
    GRAPH_VIN_MAX_SCALE:number;
    DATE_RANGE:number;
    IS_USE_UNIVERSAL_CONTROLLER_POPUP: boolean
  };
  SERVICE_CALL:{
    REPORT_START_DAYS_AGO: number;
  };
  SIM_IMPORT: {
    COL_SIM_NUMBER: string[];
    COL_PHONE_NUMBER: string[];
    COL_SIM_STATUS: string[];
    IS_SIM_STATUS_0_VALUES: string[];
    IS_SIM_STATUS_1_VALUES: string[];
    SIM_CHECK_OPERATORS: ISimCheckOperator[];
  };
  CUSTOMERS: {
    COL_SIZE: number;
    PAGE_COLS: number;
    DEFAULT_COUNTRY_CODE: string;
  };
  NETWORK_PROTECTION: {
    DEFAULT_ZOOM: number;
  };
  DASHBOARD: {
    CELL_PROVIDERS_COUNT: string;
    COMMUNICATION_INTERVALS_COUNT: string;
    DEVICE_TYPES_COUNT: string;
    INSTALL_DATES_COUNT: string;
    PROJECTS_COUNT: string;
  };
  POW_PROBLEM: {
    EXCEPTION_TYPES: string[];
    IS_USE_UNIVERSAL_CONTROLLER_POPUP: boolean;
  };
  WEATHER_STATIONS:{
    DAYS_FOR_UPDATE_MAX_EVAPORATION_RATES:number;
    DAY_PERIOD_PARAM: number;
    VALID_MEASUREMENT_RANGE: number[];
    ICON_HOVER_TOLERANCE_SECONDS: number;
  };

}

const initialState: globalState = {
  GOOGLE_MAP_KEY: '',
  BATT_REP: {
    REPORT_START_DAYS_AGO: 7,
    REPORT_INTERVAL_DAYS: 3,
    GRAPH_START_DAYS_AGO: 3,
    GRAPH_INTERVAL_DAYS: 1,
    DAY_TIME_START: '13:00',
    DAY_TIME_END: '17:00',
    NIGHT_TIME_START: '01:00',
    NIGHT_TIME_END: '05:00',
    MIN_CONNECTION_TIME: 5,
    DISCONNECTED_DAYS_IN_THE_PERIOD: 100,
    GRAPH_VBAT_MAX_SCALE: 5,
    GRAPH_VIN_MAX_SCALE:24,
    DATE_RANGE:180,
    IS_USE_UNIVERSAL_CONTROLLER_POPUP:true
  },
  SERVICE_CALL:{
    REPORT_START_DAYS_AGO: 3
  },
  SIM_IMPORT: {
    COL_SIM_NUMBER: ['SIM_ID', 'SIM ID', 'SIM_NUMBER', 'SIMNumber', 'SIM', 'ICCID'],
    COL_PHONE_NUMBER: ['PhoneNumber', 'Phone Number', 'Phone', 'Number', 'CLI', 'MSISDN'],
    COL_SIM_STATUS: ['Status code', 'sim status', 'status'],
    IS_SIM_STATUS_0_VALUES: ['NO', 'FALSE', 'DISABLE'],
    IS_SIM_STATUS_1_VALUES: ['YES', 'TRUE', 'ENABLE', 'ACTIVE'],
    SIM_CHECK_OPERATORS: [
      {
        searchString: '8997201',
        id: '1',
      },
      {
        searchString: '8997202',
        id: '2',
      },
      {
        searchString: '8997250',
        id: '50',
      },
    ],
  },
  CUSTOMERS: {
    COL_SIZE: 73,
    PAGE_COLS: 4,
    DEFAULT_COUNTRY_CODE:'972'
  },
  NETWORK_PROTECTION: {
    DEFAULT_ZOOM: 2,
  },
  DASHBOARD: {
    CELL_PROVIDERS_COUNT: 'PieCharts',
    COMMUNICATION_INTERVALS_COUNT: 'PieCharts',
    DEVICE_TYPES_COUNT: 'DoughnutCharts',
    INSTALL_DATES_COUNT: 'DoughnutCharts',
    PROJECTS_COUNT: 'PieCharts',
  },
  POW_PROBLEM: {
    EXCEPTION_TYPES: ['batT_LOW_D', 'chargE_TIME_LOW_D', 'viN_LOW_D', 'nO_VIN_D'],
    IS_USE_UNIVERSAL_CONTROLLER_POPUP:true
  },
  WEATHER_STATIONS:{
    DAYS_FOR_UPDATE_MAX_EVAPORATION_RATES:30,
    DAY_PERIOD_PARAM:7,
    VALID_MEASUREMENT_RANGE:[0,500],
    ICON_HOVER_TOLERANCE_SECONDS: 180
  }

};

const globalSlice = createSlice({
  name: 'popups',
  initialState,
  reducers: {
    setGlobalData: (state, action: PayloadAction<globalState>) => {
      state.GOOGLE_MAP_KEY = action.payload.GOOGLE_MAP_KEY;

      state.BATT_REP.REPORT_START_DAYS_AGO = action.payload.BATT_REP.REPORT_START_DAYS_AGO;
      state.BATT_REP.REPORT_INTERVAL_DAYS = action.payload.BATT_REP.REPORT_INTERVAL_DAYS;
      state.BATT_REP.GRAPH_START_DAYS_AGO = action.payload.BATT_REP.GRAPH_START_DAYS_AGO;
      state.BATT_REP.GRAPH_INTERVAL_DAYS = action.payload.BATT_REP.GRAPH_INTERVAL_DAYS;
      state.BATT_REP.DAY_TIME_START = action.payload.BATT_REP.DAY_TIME_START;
      state.BATT_REP.DAY_TIME_END = action.payload.BATT_REP.DAY_TIME_END;
      state.BATT_REP.NIGHT_TIME_START = action.payload.BATT_REP.NIGHT_TIME_START;
      state.BATT_REP.NIGHT_TIME_END = action.payload.BATT_REP.NIGHT_TIME_END;
      state.BATT_REP.MIN_CONNECTION_TIME = action.payload.BATT_REP.MIN_CONNECTION_TIME;
      state.BATT_REP.DISCONNECTED_DAYS_IN_THE_PERIOD = action.payload.BATT_REP.DISCONNECTED_DAYS_IN_THE_PERIOD;
      state.BATT_REP.GRAPH_VBAT_MAX_SCALE = action.payload.BATT_REP.GRAPH_VBAT_MAX_SCALE;
      state.BATT_REP.GRAPH_VIN_MAX_SCALE = action.payload.BATT_REP.GRAPH_VIN_MAX_SCALE;
      state.BATT_REP.DATE_RANGE = action.payload.BATT_REP.DATE_RANGE;
      state.BATT_REP.IS_USE_UNIVERSAL_CONTROLLER_POPUP = action.payload.BATT_REP.IS_USE_UNIVERSAL_CONTROLLER_POPUP;

      state.SERVICE_CALL.REPORT_START_DAYS_AGO = action.payload.SERVICE_CALL.REPORT_START_DAYS_AGO;

      state.SIM_IMPORT.COL_SIM_NUMBER = action.payload.SIM_IMPORT.COL_SIM_NUMBER;
      state.SIM_IMPORT.COL_PHONE_NUMBER = action.payload.SIM_IMPORT.COL_PHONE_NUMBER;
      state.SIM_IMPORT.COL_SIM_STATUS = action.payload.SIM_IMPORT.COL_SIM_STATUS;
      state.SIM_IMPORT.IS_SIM_STATUS_0_VALUES = action.payload.SIM_IMPORT.IS_SIM_STATUS_0_VALUES;
      state.SIM_IMPORT.IS_SIM_STATUS_1_VALUES = action.payload.SIM_IMPORT.IS_SIM_STATUS_1_VALUES;

      state.CUSTOMERS.COL_SIZE = action.payload.CUSTOMERS.COL_SIZE;
      state.CUSTOMERS.PAGE_COLS = action.payload.CUSTOMERS.PAGE_COLS;
      state.CUSTOMERS.DEFAULT_COUNTRY_CODE = action.payload.CUSTOMERS.DEFAULT_COUNTRY_CODE;


      state.NETWORK_PROTECTION.DEFAULT_ZOOM = action.payload.NETWORK_PROTECTION.DEFAULT_ZOOM;

      state.DASHBOARD.CELL_PROVIDERS_COUNT = action.payload.DASHBOARD.CELL_PROVIDERS_COUNT;
      state.DASHBOARD.COMMUNICATION_INTERVALS_COUNT = action.payload.DASHBOARD.COMMUNICATION_INTERVALS_COUNT;
      state.DASHBOARD.DEVICE_TYPES_COUNT = action.payload.DASHBOARD.DEVICE_TYPES_COUNT;
      state.DASHBOARD.INSTALL_DATES_COUNT = action.payload.DASHBOARD.INSTALL_DATES_COUNT;
      state.DASHBOARD.PROJECTS_COUNT = action.payload.DASHBOARD.PROJECTS_COUNT;

      state.POW_PROBLEM.EXCEPTION_TYPES = action.payload.POW_PROBLEM.EXCEPTION_TYPES;
      state.POW_PROBLEM.IS_USE_UNIVERSAL_CONTROLLER_POPUP = action.payload.POW_PROBLEM.IS_USE_UNIVERSAL_CONTROLLER_POPUP;

      state.WEATHER_STATIONS.DAYS_FOR_UPDATE_MAX_EVAPORATION_RATES = action.payload.WEATHER_STATIONS.DAYS_FOR_UPDATE_MAX_EVAPORATION_RATES;
      state.WEATHER_STATIONS.DAY_PERIOD_PARAM = action.payload.WEATHER_STATIONS.DAY_PERIOD_PARAM;
      state.WEATHER_STATIONS.VALID_MEASUREMENT_RANGE = action.payload.WEATHER_STATIONS.VALID_MEASUREMENT_RANGE;
      state.WEATHER_STATIONS.ICON_HOVER_TOLERANCE_SECONDS = action.payload.WEATHER_STATIONS.ICON_HOVER_TOLERANCE_SECONDS;
      
      
    },
  },
});

export const { setGlobalData } = globalSlice.actions;

export const globalSliceReducer = globalSlice.reducer;
