import { Box } from '@mui/material';
import { BaseTable, BaseTimezoneSelect, PagePreloader } from 'components';
import { t } from 'i18next';
import { useEffect, useMemo, useState, } from 'react';
import { useAppSelector } from 'shared/hooks';
import { BaseBtn, BaseInput, BigTitle, Row, SearchInput, TotalRows, Text } from 'shared/ui';
import { TimeZone } from './timeZoneModels';
import { getFormattedDateAndTime } from 'shared/lib';
import { TABLE_TITLES, TRANSLATE_COLUMNS } from './constants';
import { AddTimeZoneModal, TimeZoneEditModal } from './components';
import { useSearch, useFilter, useTimeZone } from './hooks';
import { Filter } from 'features';

export const TimeZoneManager = () => {
  const { timeZones } = useAppSelector((st) => st.timeZone);
  const { getTimeZone, isLoading } = useTimeZone()
  const onChange = (value: string) => {
    setTimeZoneID(value)
  }
  const [timeZoneID, setTimeZoneID] = useState<string>("");
  const [timeZone, setTimeZone] = useState<TimeZone>(null);
  const [openTimeZoneEditModal, setOpenTimeZoneEditModal] = useState<boolean>(false);
  const [openAddTimeZoneModal, setOpenAddTimeZoneModal] = useState<boolean>(false);
  useEffect(() => {
    getTimeZone()

  }, []);



  useEffect(() => {
    if (timeZones.length) {
      const timeZoneItem = timeZones.find(item => item.timeZoneID === parseInt(timeZoneID))
      setTimeZone(timeZoneItem)
    }

  }, [timeZoneID, isLoading]);

  const handleOpenTimeZoneEditModal = (value: number) => {
    setTimeZoneID(value.toString())
    setOpenTimeZoneEditModal(true)
  }

  const handleOpenAddTimeZoneModal = () => {
    setOpenAddTimeZoneModal(true)
  }

  const closeTimeZoneEditModal = () => {
    setOpenTimeZoneEditModal(false)
  }
  const closeAddTimeZoneModal = () => {
    setOpenAddTimeZoneModal(false)
  }
  const { searchValue, changeSearch } = useSearch();
  const { timeZoneStatusItems,
    onChangeStatusTimeZone,
    statusTimeZone,
    isSyncTimeZone,
    onChangeIsSyncTimeZone,
    timeZoneIsSyncItems


  } = useFilter();
  const timeZonesForTable: TimeZone[] = useMemo(() => {
    return timeZones.map(item => ({
      ...item,
      hasDST: item.hasDST ? "time_zones_has_dst_yes" : "time_zones_has_dst_no",
      dst: item.hasDST ? "dst_yes" : "dst_no",
      isActive: item.isActive ? "time_zones_is_active_yes" : "time_zones_is_active_no",
      utcOffset: item.utcOffset ? item.utcOffset / 3600 : item.utcOffset,
      zoneStart: item.zoneStart ? item.zoneStart * 1000 : null,
      zoneEnd: item.zoneEnd ? item.zoneEnd * 1000 : null,
      isSync: item.isSync ? "time_zones_is_sync_yes" : "time_zones_is_sync_no"
    }))
  }, [timeZones, isLoading])
  const updateTimeZoneData = () => {
    getTimeZone()
  }

  const getSearchedTimeZones = (timeZones: TimeZone[], searchValue: string) => {
    const searched = timeZones.filter((c) => {
      const values = Object.values(c);
      return values.some((v) => v?.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase().trim()));
    });
    return searched;
  };

  const filteredTimeZones = useMemo(() => {
    const searchedTimeZones = getSearchedTimeZones(timeZonesForTable, searchValue);
    // let filteredWAMessageStatuses = serchedWAMessageStatuses.filter((item) =>
    //   customerFilter ? item.customerID.toString() === customerFilter : true
    // );
    let filteredTimeZones = searchedTimeZones.filter((item) => {
      if (!statusTimeZone) return true
      if (statusTimeZone == "1" && item.isActive === "time_zones_is_active_yes") return true;
      if (statusTimeZone == "2" && item.isActive === "time_zones_is_active_no") return true;
    }
    );

    filteredTimeZones = filteredTimeZones.filter((item) => {
      if (!isSyncTimeZone) return true
      if (isSyncTimeZone == "1" && item.isSync === "time_zones_is_sync_yes") return true;
      if (isSyncTimeZone == "2" && item.isSync === "time_zones_is_sync_no") return true;
    }
    );

    return filteredTimeZones;
  }, [searchValue, timeZonesForTable, statusTimeZone, isSyncTimeZone]);

  const timezoneBaseTable = useMemo(
    () => (
      <BaseTable
        tableItems={filteredTimeZones}
        loadedItems={true}
        idField={'timeZoneID'}
        noItemsText={t('no_time_zone')}
        headerItemsPrefix={'_dashboard_details_grid_table'}

        dateTimeColumnIndexes={[4, 5]}
        selectedRowID={timeZoneID}
        maxHeight="calc(100vh - 205px)"
        height="calc(100vh - 205px)"
        tableTitles={TABLE_TITLES}
        translateColumns={TRANSLATE_COLUMNS}
        defaultScrollWidth={24}
        //translateColumns={TRANSLATE_COLUMNS}
        linkIndex={[0]}
        cellClick={(value) => handleOpenTimeZoneEditModal(value.timeZoneID)}
      //loading={loading || isPending}
      />
    ),
    [filteredTimeZones, timeZoneID, isLoading]
  );

  return (
    <>
      <PagePreloader loading={isLoading} />
      <Box>
        <Row sx={{ mb: '12px' }}>
          <BigTitle sx={{ mr: '25px' }}>{t('time_zone_manager_title')}</BigTitle>
          <BaseBtn onClick={handleOpenAddTimeZoneModal}>
            {t('add_controler_button')}
            <Text sx={{ fontSize: '25px', mb: '4px', ml: '4px' }}>+</Text>
          </BaseBtn>


          <Row sx={{ flexGrow: 1 }} />
        </Row>
      </Box >

      {/* <Row spacing="20px" alignItems="flex-end">
        <BaseTimezoneSelect title={`${t('select_all_time_zone')}`} onChange={onChange} value={timeZoneID} />
        <BaseTimezoneSelect title={`${t('select_time_zone')}`} onlyActive onChange={onChange} value={timeZoneID} />
        <BaseInput
          label={`${t('install_address_edit_modal')}`}
          name="zoneStart"
          value={timeZone && timeZone.zoneStart ? getFormattedDateAndTime(timeZone.zoneStart * 1000) : ""}
          onChange={(event) => { }}
          disabled
          small
          helperText=""
        />
        <BaseInput
          label={`${t('install_address_edit_modal')}`}
          name="zoneEnd"
          value={timeZone && timeZone.zoneEnd ? getFormattedDateAndTime(timeZone.zoneEnd * 1000) : ""}
          onChange={(event) => { }}
          disabled
          small
          helperText=""
        />
        <BaseInput
          label={`${t('daylight_saving_time')}`}
          name="Daylight Saving Time"
          value={timeZone ? (timeZone.dst ? `${t('daylight_saving_time_yes')}` : `${t('daylight_saving_time_no')}`) : ""}
          onChange={(event) => { }}
          disabled
          small
          helperText=""
        />


      </Row> */}
      <SearchInput
        value={searchValue}
        onChange={(e) => changeSearch(e.target.value)}
        placeholder={`${t('search_wa_message_status_management_page')}`}
      />

      <Row sx={{ mt: '6px' }} flexWrap="wrap" rowGap="5px" alignItems="flex-end">
        <Filter
          title={`${t('time_zone_status_filter')}`}
          value={statusTimeZone}
          onChange={onChangeStatusTimeZone}
          items={timeZoneStatusItems}
          hideAllButton
          sx={{ width: '220px' }}
          messageEmptyFilter={`${t('please_select_status_timezone_filter')}`}
          placeholder={`${t('please_select_status_timezone_filter')}`}
        />
        <Filter
          title={`${t('time_zone_is_sync_filter')}`}
          value={isSyncTimeZone}
          onChange={onChangeIsSyncTimeZone}
          items={timeZoneIsSyncItems}
          hideAllButton
          sx={{ width: '220px' }}
          messageEmptyFilter={`${t('please_select_status_timezone_filter')}`}
          placeholder={`${t('please_select_status_timezone_filter')}`}
        />
        <Row sx={{ flexGrow: 1 }} />
        <TotalRows count={filteredTimeZones.length} />
      </Row>

      <Box>
        {timezoneBaseTable}
      </Box>

      <TimeZoneEditModal
        timeZone={timeZone}
        openModal={openTimeZoneEditModal}
        onCloseModal={closeTimeZoneEditModal}
        updateTimeZoneData={updateTimeZoneData}
      />

      <AddTimeZoneModal
        timeZone={timeZone}
        openModal={openAddTimeZoneModal}
        onCloseModal={closeAddTimeZoneModal}
        updateTimeZoneData={updateTimeZoneData}
      />

    </>
  );
};
