type Link = {
  title: string;
  path: string;
};
type MenuLink = {
  title: string;
  path?: string;
  links?: Link[];
};

export const navLinks: MenuLink[] = [
  { title: 'dashboard', path: '/dashboard' },
  { title: 'customers', path: '/customers' },
   { title: 'controllers',  path:'/controllers'},
  { title: 'users', path: '/users' },
  {
    title: 'maintenance',
    links: [
      { title: 'sim_management', path: '/sim-management' },
      {
        title: 'Network protection',
        path: '/network-protection',
      },
      { title: 'fota', path: '/fota' },
      { title: 'timeZone', path: '/time-zone' },
    ],
  },
  {
    title: 'reports',
    links: [
      {
        title: 'Battery status report',
        path: '/battery-status-report',
      },
      {
        title: 'External Power problems report',
        path: '/external-Power-problems-report',
      },
      {
        title: 'Replaced equipment report',
        path: '/replaced-equipment-report',
      },
      {
        title: 'Service Calls Report',
        path: '/service-calls-report',
      },
      {
        title: 'message status',
        path: '/configuration/whatsapp/message-status',
      }

    ],
  },
  {
    title: 'experimental',
    links: [
      {
        title: 'Real-time Controller Data',
        path: '/real-time-controller-data',
      },
      {
        title: 'weather station management',
        path: '/weather-station-management',
      },
    ],
  },
  {
    title: 'configuration',
    links: [
      {
        title: 'configuration',
        path: '/configuration/system',
      },
      {
        title: 'whatsapp',
        path: '/configuration/whatsapp/templates',
      },
    ],
  },
];
